import React from "react";
import LocationButtons from "./locationButtons";
import Banner from "./banner";
import { Box } from "@mui/material";
import classes from "../css/Logo";
const Body = (props) => {
  const { branches } = props;
  return (
    <>
      <Box
        align='center'
        component='h2'
        sx={classes.bannerh2}
      >
        SHETTY & THOMAS
      </Box>
      <Box
        component='h5'
        align='center'
        sx={classes.bannerh5}>
        CHARTERED ACCOUNTANTS
      </Box>
      <LocationButtons branches={branches} />
    </>
  );
};

class Logo extends React.Component {
  render() {
    const { branches } = this.props;
    const body = <Body branches={branches} />

    return (
      <>
        <Banner
          body={body}
        />
      </>
    );
  }
}

export default Logo;