import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import CssBaseline from "@mui/material/CssBaseline";
import Header from './components/header'
import { ThemeProvider } from "@mui/material/styles";
import HomePage from './components/homePage';
import Footer from "./components/footer";
import Locations from "./components/locations";
import Contact from "./components/contact";
import Career from "./components/career";
import { appTheme } from "./css/Theme";
import { Box } from '@mui/material';

const NotFound = () => <h1>Page Not Found</h1>;

function App() {

    const [lightMode, setLightMode] = useState(() => {
        const mode = window.localStorage.getItem("displayModeST");
        return mode !== null
            ? JSON.parse(mode)
            : true;
    })
    const theme = appTheme(lightMode);
    useEffect(() => {
        window.localStorage.setItem("displayModeST", JSON.stringify(lightMode));
    }, [lightMode]);


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Box color='primary.main'>
                    <Header setLightMode={setLightMode} lightMode={lightMode} />
                    <Box className='website_body'>
                        <Switch>
                            <Redirect exact from="/" to="/homepage" />
                            <Route path="/homepage" component={HomePage} />
                            <Route path="/location" component={Locations} />
                            <Route path="/contactus"  >
                                <Contact lightMode={lightMode?'light':'dark'} />
                                </Route>
                            <Route path="/careers" >
                                <Career lightMode={lightMode?'light':'dark'} />
                                </Route>
                            <Route component={NotFound} />
                        </Switch>
                    </Box>
                    <Footer />
                </Box>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
