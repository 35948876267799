const styles = {
    divVision: (theme) => ({
        height: theme.bannerHeight,
        backgroundPositionX: "left",
        backgroundPositionY: "top",
        backgroundRepeat: "repeat-x",
        backgroundSize: "cover",
        color: "black",
        fontWeight: "bold",
    }),

    visionContent: {
        paddingTop: "101px",
        textAlign: {
           xs:"center",
            sm:"left",
            md:"right"
        },
        marginRight:{
            md:"91px"
        },
        marginLeft:{
            sm:"91px",
        }
        
    }
}



export default styles;