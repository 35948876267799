import React from 'react';
import vision_background from "./../img/home.jpg"
import classes from '../css/Vision';
import { Box } from '@mui/material';
function Vision() {
  return (
    <Box sx={classes.divVision} style={{ backgroundImage: `url(${vision_background})` }}>
      <Box sx={classes.visionContent}>
        <Box component='h2' sx={{ "fontSize": "33px", "fontWeight": "bold" }}>
          Our Vision
        </Box>
        <Box component='p' sx={{
          "marginTop": "84px",
          "fontSize": "25px",
          "fontWeight": "bold",
        }}
        >
          Service with Commitment and Professionalism
        </Box>
      </Box>
    </Box>
  );
}

export default Vision;