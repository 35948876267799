const styles = {
    sectionSpacing: (theme) => theme.sectionSpacing,
    paperSpacing: {
        paddingTop: "30px",
        paddingBottom: "30px"
    },
    logoLocations: {
        width: "100%",
        paddingTop: {
            lg: "100px",
        },
    },
    locationButtons: {
        color: "secondary.main",
        backgroundColor: "primary.contrastText",
        fontSize: "17px",
        fontWeight: 'bold',
        paddingTop: "8px",
        paddingBottom: "8px",
        minWidth: "150px",
        maxWidth: "500px",
        '&:hover': {
            backgroundColor: "action.hover",
        }
    },
    bannerh2: (theme) => ({
        ...theme.banner.h2,
        textTransform: 'uppercase'
    })
}

export default styles;