import { Box, Card, CardContent, CardHeader, Container, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import classes from "../css/Services";

const CardItem = ({ items }) => {
  const cardData = items.map((item, index) => {
    return (
      <Box
        component='li'
        sx={classes.servicesList}
        key={index}
      >
        {item}
      </Box>
    );
  })

  return (
    <>
      {cardData}
    </>
  );
}

const CustomDot = ({ active, onClick }) => {
  return (
    <li>
      <Button
        sx={active ? classes.customDotActive : classes.customDot}
        onClick={() => onClick()}
      />
    </li>
  );
};

function Services(props) {
  const [services, setServices] = useState([]);
  const json = require('./../json/services.json');
  useEffect(() => {
    const services = JSON.parse(JSON.stringify(json));
    setServices(services)
  }, [json])

  const cardData = services.map((data, index) => {
    return (
      <Box
        key={index}
        style={{
          "marginRight": "10px", "height": "100%"
          , "marginLeft": "10px"
        }}>
        <Card
          sx={classes.serviceCard}>
            {/* <Card sx={{color:"primary.main"}}> */}
          <CardHeader
            title={data.title}
            sx={classes.cardHeader}
          >          
          </CardHeader>
          {/* </Card> */}
          <CardContent>
            <ul>
              <CardItem items={data.data} />
            </ul>
          </CardContent>
        </Card>
      </Box>
    )
  })

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <Container
      maxWidth='xl'
      sx={classes.sectionSpacing}
    >
      <Box
        component={"h2"}
        textAlign='center'
        sx={{ "fontWeight": "bold" }}
      >
        Services
      </Box>
      <Box
        component='div'
        sx={classes.carouselBox}
      >
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={props.deviceType !== "mobile" ? true : false}
          autoPlaySpeed={1500}
          keyBoardControl={true}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px image-item"
          customDot={<CustomDot />}
          minimumTouchDrag={80}
        >
          {cardData}
        </Carousel>
      </Box>
    </Container>
  );
}

export default Services;
