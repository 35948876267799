import { Box, Container } from '@mui/material';
import React from 'react';
import classes from "../css/Footer";
class Footer extends React.Component {
  render() {
    return (
      <Box>
        <Container
          maxWidth='lg'
        >
          <Box
            component="div"
            sx={classes.footer}
          >
            Designed and Powered by Sunray Enterprises © 2020
          </Box>
        </Container>
      </Box>
    );
  }
}

export default Footer;