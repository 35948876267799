const styles = {
    sectionSpacing: (theme) => theme.sectionSpacing,
    test: {
        color: "primary.main",
        '&:hover': {
            backgroundColor: "action.hover"
        }
    }
}

export default styles;