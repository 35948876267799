import { createTheme } from '@mui/material/styles';
// import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

// const breakpoints = createBreakpoints({})
const appTheme = (lightMode) => {
    const options = {
        typography: {
            button: { textTransform: "capitalize" }
        },
        //  breakpoints: {
        //     values: {
        //         xs: 0,
        //         sm: 576,
        //         md: 768,
        //         lg: 992,
        //         xl: 1200,
        //     },
        // },
        sectionSpacing: {
            marginTop: "50px",
            // paddingTop: "130px",
            paddingBottom: "50px"
        },
        alignment: {
            horizontalCenter: {
                "text-align": "center"
            },
            horizontalMarginCenter: {
                marginLeft: "auto",
                marginRight: "auto",
            },
            verticalMarginCenter: {
                marginTop: "auto",
                marginBottom: "auto",
            }
        },
        space: {
            sectionSpace: {
                marginTop: "150px"
            }, headingSpace: {
                marginTop: "20px"
            },
        },
        actions: {
            //         rotate: {
            //             "-webkit-transform": "rotate(360deg)!important",
            //             transform: "rotate(360deg)!important",
            //         },
            scale: {
                transform: "scale(1.1)"
            },
        },
        style: {
            rmOutline: {
                '&:focus': {
                    outline: "none"
                },
            }
        },
        sectionHeading: {
            '&::before': {
                display: "block",
                content: '" "',
                marginTop: "-100px",
                height: "100px",
                visibility: "hidden",
                pointerEvents: "none"
            }
        },
        bannerHeight: {
            height: "500px"
        },
        banner: {
            h2: {
                color: "black",
                fontSize: "50px",
                fontFamily: "Spectral, serif"
            },
            h5: {
                color: "black",
                fontSize: "30px",
                fontFamily: "Spectral, serif"
            },
        },
        error:{
            color: "red"
        },
        success:{
            color: "green"
        }
    }

    if (lightMode) {
        return createTheme({
            ...options,
            palette: {
                type: 'light',
                primary: {
                    main: "#000",
                },
                secondary: {
                    main: '#00008B',
                    // light:"#4040FF",
                    // dark:"#00006f",
                    // contrastText:"rgba(0,0,0,0.87)"
                },
                // button: {
                //     main: "#00008B",
                // },
                // iconColor: {
                //     main: "#00008B"
                // },
                background: {
                    default: "#F0F8FF",
                    paper: "#f8f8f8"
                },
                action: {
                    hover: 'rgba(0, 0, 0, 0.04)'
                },
            }
        })
    } else {
        return createTheme({
            ...options,
            palette: {
                type: 'dark',
                common:{

                },
                primary: {
                    main: "#f8f8ff",
                    // light:"#e3f2fd",
                    // dark:"#42a5f5",
                    // contrastText:"rgba(0,0,0,0.87)"
                },
                secondary: {
                    main: 'rgb(0,219,255)',
                    // light:"#e3f2fd",
                    // dark:"#42a5f5",
                    // contrastText:"rgba(0,0,0,0.87)"
                },
                // button: {
                //     main: "rgb(0,219,255)",
                // },
                // iconColor: {
                //     main: "rgb(0,219,255)"
                // },
                background: {
                    default: "#000",
                    paper: "#121212"
                },
                action: {
                    hover: 'rgba(255, 255, 255, 0.08)'
                },
            },
        })
    }
}
export { appTheme };