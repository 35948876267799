import React from "react";
import { Box, Container, Grid } from "@mui/material";
import { Phone, LocationOn, PhoneIphone, Email, AccessTimeFilled } from '@mui/icons-material';

const ElementCheck = ({ element, faIcon, title }) => {
  if (element) {
    return (
      <Grid container >
        <Grid item xs={5}>
          <Grid container>
            <Grid item >{faIcon()}</Grid>
            <Grid item >{' '}{title}</Grid>
          </Grid>
        </Grid>
        <Grid xs={7}>
          <Box component='div' >{element}</Box>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <>
      </>
    )
  }
}

function BranchDetails({ branch }) {

  const addressRows = (rows) => {
    return (
      (rows || []).map((row, index) => {
        return (
          <Box component='div' key={index}>{row}</Box>
        );
      })
    );
  }

  return (
    <Container maxWidth='xl' >
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems='center'>
        <Grid item xs={12} md={7}>
          <iframe
            src={branch.map}
            height={450} frameBorder={0} style={{ "border": "5px dimgrey double", "width": "100%" }}
            allowFullScreen=""
            aria-hidden="false" title="branch-map"
            tabIndex="0" />
        </Grid>
        <Grid item xs={12} md={5}>
          <ElementCheck
            element={addressRows(branch.address)}
            faIcon={() => <LocationOn color="primary" />}
            title={'Address'}
          />
          <ElementCheck
            element={branch.mobile}
            faIcon={() => <PhoneIphone color="primary" />}
            title={'Mobile'}
          />
          <ElementCheck
            element={branch.phone}
            faIcon={() => <Phone color="primary" />}
            title={'Phone'}
          />
          <ElementCheck
            element={branch.email}
            faIcon={() => <Email color="primary" />}
            title={'Email'}
          />
          <ElementCheck
            element={branch.hours}
            faIcon={() => <AccessTimeFilled color="primary" />}
            title={'Office Hours'}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default BranchDetails;