const styles = {
    sectionSpacing: (theme) => theme.sectionSpacing,
    carouselBox: {
        margin: "20px 20px 0px 20px",
        paddingBottom: "10px",
        '& .MuiCardContent-root': {
            paddingLeft: "0px",
        }
    },
    cardHeader: {
        backgroundColor:(theme) => theme.palette.type==='light'?theme.palette.grey[600]: theme.palette.grey[800],
        textAlign: "center",
    },
    serviceCard: {
        color: "primary.main",
        height: "100%",
    },
    customDot: {
        backgroundColor: (theme) => theme.palette.grey[400],
        width: "100%",
        height: "3px",
        borderWidth: "0",
        padding: "0"
    },
    customDotActive: {
        backgroundColor: 'secondary.main',
        width: "100%",
        height: "3px",
        borderWidth: "0",
        padding: "0"
    },
    servicesList: {
        textAlign: 'justify',
        justifySelf: 'stretch',
        width: '100%',
    }
}

export default styles;