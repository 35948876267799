import React from 'react';
import img_logo from "./../img/ICAI_logo.png"
import classes from '../css/About';
import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';

function About() {
  return (
    <Container maxWidth="xl" sx={classes.sectionSpacing} >
      <Box
        component={"img"}
        sx={classes.center}
        src={img_logo}
        alt='ICAI'
      />
      <Box
        component={"h2"}
        textAlign='center'
        sx={{ "fontWeight": "bold" }}
      >
        About Us
      </Box>
      <Container >
        <Typography paragraph textAlign='center' ><strong>CA. Praveen Kumar
          Shetty</strong> and <strong>CA. Joseph Thomas</strong> as partners
        </Typography>
        <Typography paragraph >
          started the firm on July 7, 1988 at Mangalore in Karnataka. The first branch of the firm was started in
          1991 at Cochin in Kerala. Since then, two more branches have been added at Bangalore in Karnataka and in
          Goa.
        </Typography>
        <Typography paragraph>
          The firm has grown to become a Category 1 Auditing Firm as per RBI norms with the addition of three more
          partners namely:
        </Typography>
        <ul>
          <Box component='li' sx={{ "width": "100%" }}>CA. Kuruvila Kuriakose</Box>
          <Box component='li' sx={{ "width": "100%" }}>CA. D.S. Anand</Box>
          <Box component='li' sx={{ "width": "100%" }}>CA. Mahesh Jambulingam.</Box>
        </ul>
      </Container>
    </Container>
  );
}

export default About;