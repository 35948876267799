const styles = {
    logo: {
        height: '50px',
        color: "secondary.main",
        padding: '0px 2px 0px 2px',
        minWidth: '50px',
        transition: (theme) => theme.transitions.create(["transform"], {
            duration: theme.transitions.duration.standard
        }),
        '& svg': {
            transition: (theme) => theme.transitions.create(["transform"], {
                duration: theme.transitions.duration.standard
            }),
            '&:hover': (theme) => ({
                ...theme.actions.rotate,
            })
        },
        '&:hover': (theme) => ({
            ...theme.actions.scale,
        }),
        fill: "secondary.main",
    },
    st_logo: {
        height: "32px",
        marginRight: "5px",
        alignSelf: "center",
        position: "relative"

    },
    grow: {
        flexGrow: 1,
    },
    companyName: {
        display:{
            xs: "none",
            sm:"block"
        }
    },
    sectionDesktop: {
        display:{
            xs: "none",
            md:"flex"
        }
    },
    sectionMobile: {
        display:{
            xs: "flex",
            md:"none"
        }
    },
    navButtons: {
        color: 'secondary.main',
        // '&:hover': {
        //     backgroundColor: "secondary.light",
        // },
    },
    menuButtons: {
        justifyContent: "center",
        margin: "auto",
        color: 'secondary.main',
        // '&:hover': {
        //     backgroundColor: "secondary.light",
        // },
    }
}

export default styles;