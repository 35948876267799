import React from "react";
import logo_background from "./../img/logo.jpg"
import classes from "../css/Banner";
import { Box } from "@mui/material";

function Banner({ body }) {
  return (
    <Box component="div" sx={classes.logo_background} style={{ backgroundImage: `url(${logo_background})` }}>
      <Box component="div" sx={classes.div_logo}>
        <Box component="div" sx={classes.sub_div_logo}>
          {body}
        </Box>
      </Box>
    </Box>
  );
}

export default Banner;