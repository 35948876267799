import { Box } from "@mui/system";
import React from "react";
import Banner from "./banner";
import ContactForm from "./contactForm";
import classes from "../css/Contact";
const BannerBody = ({ branches }) => {
  return (
    <>
     <Box
        component='h2'
        align='center'
        sx={classes.bannerh2}>
        Contact Us
      </Box>      
      <br/>
      <Box
        component='h2'
        align='center'
        sx={classes.bannerh5}
      >
       We would like to hear from you
      </Box>
    </>
  );
};

function Contact({lightMode}) {

    const bannerBody = <BannerBody/>
    return (
      <>
        <Banner body={bannerBody}/>
        <Box component="div" sx={classes.sectionSpacing}>
        <ContactForm lightMode={lightMode}/>
        </Box>
        
      </>
    );
  }

export default Contact;