const styles = {
    logo_background :{
        /*filter: blur(8px);*/
        backgroundPositionX: "center",
        backgroundPositionY: "80%",
        backgroundSize: "cover",
        backgroundRepeat: 'no-repeat',
    },
    

    div_logo :(theme)=>({
        /*background: linear-gradient(135deg, rgb(0,191,255), rgba(0,191,255,0.13));*/
        width: "100%",
        height: theme.bannerHeight,
        display: "inline-flex",
        alignItems: "center",
        textAlign: "center"
    }),
    
    sub_div_logo: {
        width:"100%"
    },
    bannerh2:(theme)=>()=>({
        ...theme.banner.h2,
        marginTop: "30px" 
    }),
    bannerh5:(theme)=>({
        ...theme.banner.h5,
        fontStyle: "italic"
    })
}

export default styles;

// .div_logo {
//     /*background: linear-gradient(135deg, rgb(0,191,255), rgba(0,191,255,0.13));*/
//     width: 100%;
//     height: 500px;
//     display: inline-flex;
//     align-items: center;
//     text-align: center;
// }

// .sub_div_logo {
//     width:100%;
// }

