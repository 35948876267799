import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import Banner from "./banner";
import classes from "../css/Career";
import CareerForm from "./careerForm";

const BannerBody = ({ branches }) => {
  return (
    <>
      <Box
        component='h2'
        align='center'
        sx={classes.bannerh2}>
        Careers
      </Box>
      <br />
      <Box
        component='h2'
        align='center'
        sx={classes.bannerh5}
      >
        Join Us
      </Box>
    </>
  );
};

function Career({lightMode}) {
  const [branches, setBranches] = useState({})
  const [positions, setPositions] = useState([])
  useEffect(() => {
    const json = require('./../json/locations.json');
    const branches = JSON.parse(JSON.stringify(json));
    setBranches(branches)
    const jsonPositions = require('./../json/positions.json');
    const positions = JSON.parse(JSON.stringify(jsonPositions));
    setPositions(positions)
  }, [])
  const bannerBody = <BannerBody />
  return (
    <>
      <Banner
        body={bannerBody}
      />
      <Box
        component="div"
        sx={classes.sectionSpacing}
      >
        <CareerForm
          branches={branches}
          positions={positions}
          lightMode={lightMode}
        />
      </Box>
    </>
  );
}

export default Career;