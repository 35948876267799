// import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  //  Button, MobileStepper, Typography, Paper,
  Container, Box, Grid, Button
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "../css/Notifications";
function Notifications() {
  const json = require('./../json/notification.json');
  const [notifications, setNotifications] = useState([]);
  // const [activeStep, setActiveStep] = React.useState(0);
  // const [maxSteps, setMaxSteps] = useState(0);

  useEffect(() => {
    const notifications = JSON.parse(JSON.stringify(json));
    setNotifications(notifications);
  }, [json])

  // useEffect(() => {
  //   setMaxSteps(notifications.length)
  // }, [notifications])

  const links = () => notifications.map((link, index) =>
    <Grid
      key={index}
      item
      lg={2}
      xs={12}
      sm={6}
      md={3}
      align='center'
    >
      <Button
        variant='body2'
        underline="none"
        href={link.link}
        target='_blank'
        rel="noopener noreferrer"
        sx={classes.test}
      >
        {link.linkText}
      </Button>
      {links.linkText}
    </Grid>)

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  if (notifications.length === 0) {
    return <></>
  } else {
    return (
      <Container
        maxWidth='xl'
        sx={classes.sectionSpacing}
      >
        <Box
          component={"h2"}
          textAlign='center'
          sx={{ "fontWeight": "bold" }}

        >
          Links
        </Box>
        <Grid
          container
          justifyContent='space-evenly'
          spacing={2}
        >
          {links()}
        </Grid>

        {/* <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
          <Paper
            square
            elevation={0}
            sx={{
              height: 50,
              pl: 2,
              bgcolor: 'background.default',
            }}
          >
            <Typography align='center' variant="h5">{notifications[activeStep].description}</Typography>
            <Grid container justifyContent="center">
              <Link
                variant='body2'
                underline="none"
                href={notifications[activeStep].link}
                color="text.secondary"
                target='_blank'
                rel="noopener noreferrer"
              >
                {notifications[activeStep].linkText}
              </Link>

            </Grid>
            <Typography align='center' variant="body1">{notifications[activeStep].data}</Typography>
          </Paper>

          <MobileStepper
            // variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {
                  <KeyboardArrowRight />
                }
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {
                  <KeyboardArrowLeft />
                }
                Back
              </Button>
            }
          />
        </Box> */}
      </Container>
    );
  }

}

export default Notifications;