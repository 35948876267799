import axios from "axios";

const API_PATH = "https://shettyandthomas-api.herokuapp.com";
// const API_PATH = "http://localhost:8000";
const sendContactInfo = async(contactInfo) =>{
return await axios({
  method: 'POST',
  url: `${API_PATH}/api/mail/contact`,
  headers: {
    'Content-Type': 'application/json',
  },
  data: contactInfo
}).then((response) => {
  return response;
})
  .then(({ data }) => {
    return (data);
  }).catch(err => err.response.data)
}

export default sendContactInfo;