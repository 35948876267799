const styles = {
    sectionSpacing : (theme)=>theme.sectionSpacing,
    center:(theme)=>
    ({
        ...theme.alignment.horizontalMarginCenter,
        display: "block",
    })
    // .div_about {
    //     color: #000000;
    //     /*background: linear-gradient(135deg, rgb(196,208,255), rgba(33,169,175,0.13)) #184e8e;*/
    //     padding-bottom: 30px;
    //     padding-top: 30px;
    //     font-size: 18px;
    // }
}

export default styles;