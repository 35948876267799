import React, { useState, useEffect } from "react";
import Banner from "./banner";
import URLSearchParams from 'url-search-params';
import LocationButtons from "./locationButtons";
import BranchDetails from "./branchDetails";
import { Box } from "@mui/material";
import classes from "../css/Locations";
const Body = (props) => {
  const { branch, branches } = props;
  return (
    <>
      <Box
        component='h2'
        align='center'
        sx={classes.bannerh2}
      >
        {branch.branchName || ""}
      </Box>
      <LocationButtons
        branches={branches}
      />
    </>
  );
};

function Locations({ location: { search } }) {
  const params = new URLSearchParams(search);
  const location = params.get('branch');
  const [branch, setBranch] = useState({});
  const [branches, setBranches] = useState({});

  useEffect(() => {
    const json = require('./../json/locations.json');
    const branches = JSON.parse(JSON.stringify(json));
    setBranches(branches);
    const branch = branches[location];
    setBranch(branch)
  }, [location])

  return (
    <>
      <Banner body={<Body branch={branch} branches={branches} />} />
      <Box component="div" sx={classes.sectionSpacing}>
        <BranchDetails branch={branch} />
      </Box>
    </>
  );
}

export default Locations;