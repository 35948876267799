const styles = {
    sectionSpacing: (theme) => theme.sectionSpacing,
    paperSpacing: {
        paddingTop: "30px",
        paddingBottom: "30px"
    },
    stackFieldSpacing: {
        marginTop: "8px!important"
    },
    bannerh2: (theme) => theme.banner.h2,
    bannerh5: (theme) => theme.banner.h5,
    error: (theme) => theme.error,
    success: (theme => theme.success),
    formColors:(theme)=>({
        color:theme.palette.type==='light'?'inherit':'primary.main',
        '& label,input,textarea,#phone-helper,#email-helper,svg,legend':{
            color:theme.palette.type==='light'?'inherit':'primary.main',
        },
        '& fieldset':{
            borderColor:theme.palette.type==='light'?'':'primary.main',
            color:theme.palette.type==='light'?'inherit':'primary.main',
            
        },        
    }),
    /*joinus*/
    // .radio-check input {
    //     margin-top: 7px;
    // }
}

export default styles;