const styles = {
    sectionSpacing : (theme)=>theme.sectionSpacing,
    paperSpacing:{
        paddingTop: "30px",
        paddingBottom:"30px"
    },
    bannerh2:(theme)=>theme.banner.h2,
    bannerh5:(theme)=>({
        ...theme.banner.h5,
        fontStyle: "italic",
    }),
    error:(theme)=>theme.error,
    success:(theme=>theme.success),
    formColors:(theme)=>({
        color:theme.palette.type==='light'?'inherit':'primary.main',
        '& label,input,textarea,#phone-helper,#email-helper':{
            color:theme.palette.type==='light'?'inherit':'primary.main',
        },
        '& fieldset':{
            borderColor:theme.palette.type==='light'?'':'primary.main',
            color:theme.palette.type==='light'?'inherit':'primary.main',
        }
    })
}

export default styles;