import { Button, Container, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import classes from '../css/Locations';
const LocButton = ({ branch, param }) => {
  const address = `/location?branch=${param}`;
  return (
    <Grid
      item
      md={3}
      sm={6}
    >
      <Button
        sx={classes.locationButtons}
        variant="contained"
        href={address}
      >
        {branch.button}
      </Button>
    </Grid>
  )
}

function LocationButtons({ branches }) {

  const locButton = (keys) =>
    (keys || []).map((key, index) => {
      return (
        <LocButton key={index} branch={branches[key]} param={key} />
      );
    })

  const addButton = () => {
    const keys = [];
    for (let key in branches) {
      keys.push(key)
    }
    return (
      <>
        {locButton(keys)}
      </>
    );
  }

  return (
    <Container
      maxWidth='lg'
    >
      <Box
        sx={classes.logoLocations}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent='space-evenly'
        >
          {addButton()}
        </Grid>
      </Box>
    </Container>
  );
}

export default LocationButtons;