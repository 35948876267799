import { Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputLabel, OutlinedInput, Paper, Radio, RadioGroup, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import classes from "../css/Career";
import sendCareerInfo from "../services/sendcareerInfo";
import ReCAPTCHA from 'react-google-recaptcha';

const LocationCheck = ({ branch, param, handleChange }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox id={param} onChange={handleChange} name="location" />
      }
      label={branch.branchName}
    />
  )
}

const PositionRadio = ({ position }) => {
  return (
    <FormControlLabel value={position.unique} control={<Radio />} label={position.role} />
  )
}

function CareerForm({ branches, positions,lightMode }) {
  const defPosition = 'chartered accountant';
  // const [validated, setValidated] = useState(false);
  const [valid, setValid] = useState(false)
  const [careerInfo, setCareerInfo] = useState({
    fname: '',
    mname: '',
    lname: '',
    email: '',
    phone: '',
    position: defPosition,
    location: [],
    captcha: '',
    // resume: '',
  });
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);


  const handleChange = (event) => {
    const { id, value } = event.target;
    setCareerInfo({ ...careerInfo, [id]: value })
  }

  useEffect(() => {
    const { fname, mname, lname, email, phone, position, location, captcha } = careerInfo
    if(fname!==''&&mname!==''&&lname!==''&&email!==''&&phone!==''&&position!==''&&location.length>0&&captcha!==''){
      setValid(true)
    }else {
      setValid(false)
    }

  }, [careerInfo])

  // const handleFileChange=(event) =>{
  //   const { id, files } = event.target;
  //   setCareerInfo({...careerInfo, [id]: files[0]})
  // }

  const handleCheckChange = (event) => {
    const { name, id } = event.target;
    const { location } = careerInfo;
    if (location.includes(id)) {
      location.pop(id);
    } else {
      location.push(id);
    }
    setCareerInfo({ ...careerInfo, [name]: location })
  }

  const handleRadioChange = (event) => {
    const { value, name } = event.target;
    setCareerInfo({ ...careerInfo, [name]: value })
  }
  const getCaptcha = (value) => {
    setCareerInfo({ ...careerInfo, captcha: value })
  }

  const captchaError = ()=>{
    setCareerInfo({ ...careerInfo, captcha: "value" })
  }

  const handleSubmit = async (event) => {
    setMailSent(false);
    setError(null);

    event.preventDefault();
    // const form = event.currentTarget;
    // console.log(form.checkValidity())
    if (!valid) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const data = await sendCareerInfo(careerInfo)
      if (data.hasOwnProperty("error")) {
        setError(data.error)
      } else {
        setMailSent(data.message)
      }
    }
    setValid(false)
  };

  const submitStatus = () => {
    if (error) {
      return <Box align='center' sx={classes.error}>{error}</Box>
    } else {
      return <Box align='center' sx={classes.success}>{mailSent}</Box>
    }
  }

  const locCheck = (keys) =>
    (keys || []).map((key, index) => {
      return (
        <LocationCheck
          key={index}
          branch={branches[key]}
          param={key}
          handleChange={handleCheckChange}
        />
      );
    })

  const addCheck = () => {
    const keys = [];
    for (let key in branches) {
      keys.push(key)
    }
    return (
      <>
        {locCheck(keys)}
      </>
    );
  }

  const positionRadio = positions.map((position, index) => {
    return (
      <PositionRadio
        position={position}
        key={index}
      />
    );
  })



  return (
    <Container maxWidth='xl' sx={{ color: "blue" }}>
      <Paper sx={classes.paperSpacing}>
        <Container maxWidth="lg">
          {submitStatus()}
          <Box
            component="form"
            // validated={false}
            onSubmit={handleSubmit}
            sx={classes.formColors}
          >
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, md: 2 }}>
              <FormControl margin="dense" fullWidth required>
                <InputLabel htmlFor="fname"  >First Name</InputLabel>
                <OutlinedInput id='fname'
                  aria-describedby="fname-text" onBlur={handleChange} placeholder='Enter First Name' type='text' label="First Name" />
              </FormControl>
              <FormControl margin="dense" sx={classes.stackFieldSpacing} fullWidth  >
                <InputLabel htmlFor="mname">Middle Name</InputLabel>
                <OutlinedInput id='mname'
                  aria-describedby="mname-text" onBlur={handleChange} placeholder='Enter Middle Name' type='text' label="Middle Name" />
              </FormControl>
              <FormControl margin="dense" sx={classes.stackFieldSpacing} fullWidth required >
                <InputLabel htmlFor="lname">Last Name</InputLabel>
                <OutlinedInput id='lname'
                  aria-describedby="lname-text" onBlur={handleChange} placeholder='Enter Last Name' type='text' label="Last Name" />
              </FormControl>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, md: 2 }}>
              <FormControl margin="dense" fullWidth required >
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <OutlinedInput id='email'
                  aria-describedby="email-text" onBlur={handleChange} placeholder='Enter Email ID' type='email' label="Email Address" />
                <FormHelperText error={false} disabled id="email-helper">We'll never share your email address with anyone else.</FormHelperText>
              </FormControl>
              <FormControl margin="dense" sx={classes.stackFieldSpacing} fullWidth required >
                <InputLabel htmlFor="phone">Phone Number</InputLabel>
                <OutlinedInput id='phone'
                  aria-describedby="phone-text" onBlur={handleChange} placeholder='Enter Phone Number' type='tel' label="Phone Number" inputProps={{ className: classes.input, pattern: "[1-9]{1}[0-9]{9}" }} />
                <FormHelperText error={false} disabled id="phone-helper">We'll never share your phone number with anyone else.</FormHelperText>
              </FormControl>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, md: 2 }} alignItems="center" justifyContent='center'>
              <FormControl margin="dense" fullWidth >
                <FormLabel id="position">Positions</FormLabel>
                <RadioGroup
                  aria-labelledby="position"
                  name="position"
                  onChange={handleRadioChange}
                  defaultValue={defPosition}
                >
                  {positionRadio}
                </RadioGroup>
              </FormControl>


              <FormControl margin="dense" sx={classes.stackFieldSpacing} component="fieldset" variant="standard" fullWidth>
                <FormLabel component="legend">Preferred Location</FormLabel>
                <FormGroup>
                  {addCheck()}
                </FormGroup>
              </FormControl>
            </Stack>
            <Grid container spacing={2}>
              <Grid item>
                <ReCAPTCHA
                 theme={lightMode}
                 onErrored={captchaError}
                  sitekey="6Lc_fFEeAAAAADV4Gv_OzdR7SQgjM_3zHlV4OdUb"
                  onChange={getCaptcha}
                />
              </Grid>
              <Grid item >
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={!valid}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>

          </Box>

          {/*<Form.Group controlId="resume">*/}
          {/*  <Form.File*/}
          {/*    className="position-relative"*/}
          {/*    required*/}
          {/*    name="file"*/}
          {/*    label="Resume"*/}
          {/*    onChange={this.handleFileChange}*/}
          {/*    isInvalid={true}*/}
          {/*    id="resume"*/}
          {/*    feedbackTooltip*/}
          {/*  />*/}
          {/*  <Form.Control.Feedback type="invalid">*/}
          {/*    Please attach your Resume.*/}
          {/*  </Form.Control.Feedback>*/}
          {/*</Form.Group>*/}

        </Container>
      </Paper>
    </Container>
  );

}


export default CareerForm;