
import { Container, Paper, Box, Grid, InputLabel, FormControl, FormHelperText, Button, OutlinedInput } from "@mui/material";
import React, { useEffect, useState } from "react";
import sendContactInfo from "../services/sendContactInfo";
import classes from "../css/Contact";
import ReCAPTCHA from 'react-google-recaptcha';

function ContactForm({lightMode}) {
  // const [validated, setValidated] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    captcha: '',
  })
  const [valid, setValid] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null)
  const handleChange = (event) => {
    const { id, value } = event.target;
    setContactInfo({
      ...contactInfo, [id]: value
    })
  }

  useEffect(() => {
    const { name, email, phone, message, captcha } = contactInfo
    if (name !== '' && email !== '' && phone && message !== '' && captcha !== '') {
      setValid(true)
    } else {
      setValid(false)
    }
  }, [contactInfo]);

  const getCaptcha = (value) => {
    setContactInfo({
      ...contactInfo, captcha: value
    })
  }
  const captchaError = ()=>{
    setContactInfo({
      ...contactInfo, captcha: ""
    })
  }

  const handleSubmit = async (event) => {
    setMailSent(false);
    setError(null)

    event.preventDefault();
    // const form = event.currentTarget;
    if (!valid) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // axios({
      //   method: 'post',
      //   url: `${API_PATH}`,
      //   headers: { 'content-type': 'application/json' },
      //   data: contactInfo
      // }).then(result => {
      //   this.setState({
      //     mailSent: result.data
      //   })
      // })
      //   .catch(error => this.setState({ error: error.message }));

      const data = await sendContactInfo(contactInfo)
      if (data.hasOwnProperty("error")) {
        setError(data.error)
      } else {
        setMailSent(data.message)
      }
    }
    setValid(false)
    // setValidated(true);
  };

  const submitStatus = () => {
    if (error) {
      return <Box
        align='center'
        sx={classes.error}
      >
        {error}
      </Box>
    } else {
      return <Box
        align='center'
        sx={classes.success}
      >
        {mailSent}
      </Box>
    }
  }

  return (
    <Container
      maxWidth='xl'
    >
      <Paper
        sx={classes.paperSpacing}
      >
        <Container
          maxWidth="lg"
        >
          {submitStatus()}
          <Box
            component="form"
            // noValidate={false}
            onSubmit={handleSubmit}
            sx={classes.formColors}
          >
            <FormControl
              margin="dense"
              fullWidth
              required
            >
              <InputLabel
                htmlFor="name"
              >
                Name
              </InputLabel>
              <OutlinedInput
                id='name'
                aria-describedby="contact-name-text"
                onBlur={handleChange}
                placeholder='Enter Name'
                type='text'
                label="Name"
              />
            </FormControl>

            <FormControl
              margin="dense"
              fullWidth
              required >
              <InputLabel
                htmlFor="email"
              >
                Email Address
              </InputLabel>
              <OutlinedInput
                id='email'
                aria-describedby="contact-email-text"
                onBlur={handleChange}
                placeholder='Enter Email'
                type='email'
                label="Email Address"
              />
              <FormHelperText
                error={false}
                disabled id="email-helper"
              >We'll never share your email address with anyone else.
              </FormHelperText>
            </FormControl>

            <FormControl
              margin="dense"
              fullWidth
              required
            >
              <InputLabel
                htmlFor="phone"
              >
                Phone Number
              </InputLabel>
              <OutlinedInput
                id='phone'
                aria-describedby="contact-phone-text"
                onBlur={handleChange}
                placeholder='Enter Phone Number'
                type='tel'
                label="Phone Number"
                inputProps={{
                  className: classes.input,
                  pattern: "[1-9]{1}[0-9]{9}"
                }}
              />
              <FormHelperText
                error={false}
                disabled id="phone-helper"
              >We'll never share your phone number with anyone else.
              </FormHelperText>
            </FormControl>

            <FormControl
              margin="dense"
              fullWidth
              required
            >
              <InputLabel
                htmlFor="message"
              >Message
              </InputLabel>
              <OutlinedInput
                id='message'
                aria-describedby="message-text"
                onBlur={handleChange}
                placeholder='Enter Message'
                type='textarea'
                label="Message"
                inputProps={{
                  className: classes.input,
                  pattern: "[a-zA-Z0-9'.,]+"
                }}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item>
                <ReCAPTCHA
                theme={lightMode}
                // size="compact"
                onErrored={captchaError}
                  sitekey="6Lc_fFEeAAAAADV4Gv_OzdR7SQgjM_3zHlV4OdUb"
                  onChange={getCaptcha}
                />
              </Grid>
              <Grid item >
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={!valid}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Paper>
    </Container>
  )
}

export default ContactForm;