import axios from "axios";
const API_PATH = "https://shettyandthomas-api.herokuapp.com";
// const API_PATH = "http://localhost:8000";
const sendCareerInfo = async (careerInfo) => {
  // console.log(careerInfo)

  // const { resume, fname, lname, mname, email,phone, location, position } = careerInfo;

  // const data = new FormData()
  // data.append('resume',resume, resume.name)
  // data.append('fname',fname)
  // data.append('mname',mname)
  // data.append('lname',lname)
  // data.append('email',email)
  // data.append('phone',phone)
  // data.append('location',location)
  // data.append('position',position)
// let temp =
//   careerInfo.location = await careerInfo.location.join(",")


  return await axios({
    method: 'POST',
    url: `${API_PATH}/api/mail/careers`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: careerInfo
  }).then((response) => {
    return response;
  })
    .then(({ data }) => {
      return (data);
    }).catch(err => err.response.data)
}
export default sendCareerInfo;



