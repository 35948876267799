import React from 'react';
import { useState } from "react";
import img_ca from "./../img/ca.jpg"
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import classes from "../css/Header";
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// const useStyles = makeStyles(styles);

function Header(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isMenuOpen = Boolean(anchorEl);

    const handleSwitchMode = () => {
        props.setLightMode(!props.lightMode);
    }

    const handleMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleSignInMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSignInMenuClose = () => {
        setAnchorEl(null);
        handleMenuClose();
    };

    const signInMenuId = 'primary-sign-in-menu';
    const renderSignInMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={signInMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleSignInMenuClose}
        >
            <MenuItem 
            component='a'
             href="https://shettyandthomas.com:2096/"
                aria-label="link to sign"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleSignInMenuClose}
                sx={classes.menuButtons}
            >
                Account
            </MenuItem>
            <MenuItem
                component='a'
                aria-label="link to sign"
                href="https://onedrive.live.com/about/en-us/signin/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleSignInMenuClose}
                sx={classes.menuButtons}
            >
                Files
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMenuClose}
        >

            <MenuItem component='a' href="/careers"
                onClick={handleMenuClose}
                aria-label="link to careers section"  sx={classes.menuButtons}>
                Careers
            </MenuItem>

            <MenuItem component='a' href="/contactus"
                onClick={handleMenuClose}
                aria-label="link to contact section" sx={classes.menuButtons}>
                Contact us
            </MenuItem>
            <MenuItem
                component='a'
                onClick={handleSignInMenuOpen}
                aria-label="link to sign in"  sx={classes.menuButtons}>
                Sign in
                <ArrowDropDownIcon />
            </MenuItem>

            <MenuItem component='a' >

                <IconButton
                    aria-label="switch mode"
                    onClick={handleSwitchMode}
                    color="inherit"
                    sx={classes.menuButtons}
                >
                    {props.lightMode && <Brightness7Icon />}
                    {!props.lightMode && <Brightness4Icon />}
                </IconButton>
            </MenuItem>
        </Menu>
    );

    return (
        <Box component="div" sx={classes.grow}>
            <AppBar position="fixed" color='inherit'>
                <Container maxWidth='xl'>
                    <Toolbar>
                        <Button
                            edge="start"
                            sx={classes.logo}
                            // color="inherit"
                            aria-label="open drawer"
                            href="/homepage"
                        >
                            <Box component={"img"} sx={classes.st_logo} src={img_ca} alt='CA' />
                            <Box component="div" sx={classes.companyName} variant="body1">Shetty & Thomas</Box>
                        </Button>

                        <Box component="div" sx={classes.grow} />
                        <Box component="div" sx={classes.sectionDesktop}>

                            <Button href="/careers"
                                aria-label="link to careers section"
                                sx={classes.navButtons}
                            >
                                Careers
                            </Button>

                            <Button href="/contactus" aria-label="link to contact section"
                                sx={classes.navButtons}
                            >
                                Contact us
                            </Button>
                            <Button aria-label="link to sign"
                                onClick={handleSignInMenuOpen}
                                sx={classes.navButtons}
                            >
                                Sign In
                                <ArrowDropDownIcon />
                            </Button>
                            <IconButton
                                aria-label="switch mode"
                                onClick={handleSwitchMode}
                                sx={classes.navButtons}
                                color='secondary'
                            >
                                {props.lightMode && <Brightness7Icon />}
                                {!props.lightMode && <Brightness4Icon />}
                            </IconButton>
                        </Box>
                        <Box component="div" sx={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                            >
                                <MenuIcon sx={classes.navButtons} />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            {renderMobileMenu}
            {renderSignInMenu}
            <Toolbar />
        </Box>
    );
}

export default Header;