import React from "react";
// import {
//   Row, Col, ,
// } from 'react-bootstrap';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import img_praveen from "./../img/Praveen Kumar PHOTO.jpg";
import img_joseph from "./../img/JT_Photo.jpg";
import img_mahesh from "./../img/Mahesh Jambulingam.jpg";
import img_ananda from "./../img/D S Ananda.jpg";
import img_kuruvilla from "./../img/kuruvilla.jpg";
import { Box, Container, Grid, Typography } from "@mui/material";
import classes from "../css/Team";
class Team extends React.Component {
  render() {
    return (
      <Container
        maxWidth='xl'
        sx={classes.sectionSpacing}
      >
        <Box>
          <Box
            component={"h2"}
            textAlign='center'
            sx={{ "fontWeight": "bold" }}
          >
            Team
          </Box>
          <Typography
            paragraph
            align="center"
          >
            Meet our team of expert professionals who have proven their mettle in the industry.
          </Typography>
          <Grid
            container
            sx={classes.peopleRow}
          >
            <Grid
              sx={classes.partnerSpacing}
              item
              xs={12}
              md={6}
              lg={4}
            >
              <Flippy
                flipOnHover={false}
                flipOnClick={true}
                flipDirection="horizontal"
                ref={(r) => this.flippy = r}
                style={{ width: '100%', marginBottom: "20px" }}
              >
                <FrontSide
                  style={classes.flippyBody}
                >
                  <Box
                    sx={classes.flippyColor}
                    textAlign='center'
                  >
                    <Box
                      component={"img"}
                      sx={classes.partnerImg}
                      src={img_praveen}
                      alt='Praveen Kumar Shetty'
                    />
                    <Box
                      component='h3'
                      sx={classes.partnerName}>
                      Praveen Kumar Shetty
                    </Box>
                    <Typography
                      paragraph
                      sx={classes.partnerTitle}
                      align='center'
                    >
                      <strong>
                        CHARTERED ACCOUNTANT
                      </strong>
                    </Typography>
                  </Box>
                </FrontSide>
                <BackSide
                  style={classes.flippyBody}
                >
                  <Box
                    sx={classes.flippyColor}
                  >
                    <Typography
                      paragraph
                      sx={classes.description}
                    >
                      Commerce Graduate from St. Aloysius College, Mangalore and a Law Graduate from S.D.M. Law College, Mangalore. &nbsp;Articled from M/s R.G.N Price and Co. He is a&nbsp;
                      <strong>
                        Fellow Member of the Institute of Chartered Accountants of India
                      </strong>&nbsp;
                      since 1988. He has vast experience in the field of Internal Audit of coffee, tea plantations and coffee curing works and conversant with Karnataka Agricultural Income tax matters.
                    </Typography>
                    <Typography
                      paragraph
                      sx={classes.description}
                    >
                      He has handled various assignments during his professional career including Internal Audit, Statutory Audit, Taxation matters of various clients, audit of Corporates, Firms, Individuals, Branch Audits of banks, Insurance Audit, Audit of Multi state and other Co-operative Societies, Financial Planning/ Restructuring, Company law matters and specialization in VAT and GST Law. He has successfully undergone the training program of ISA Course conducted by ICAI. He is the partner in charge at Mangalore.
                    </Typography>
                  </Box>
                </BackSide>
              </Flippy>
            </Grid>

            <Grid
              sx={classes.partnerSpacing}
              item
              xs={12}
              md={6}
              lg={4}
            >
              <Flippy
                flipOnHover={false}
                flipOnClick={true}
                flipDirection="horizontal"
                ref={(r) => this.flippy = r}
                style={{ width: '100%', marginBottom: "20px" }}
              >
                <FrontSide
                  style={classes.flippyBody}
                >
                  <Box
                    sx={classes.flippyColor}
                    textAlign='center'
                  >
                    <Box
                      component={"img"}
                      sx={classes.partnerImg}
                      src={img_joseph}
                      alt='Joseph Thomas'
                    />
                    <Box
                      component='h3'
                      sx={classes.partnerName}>
                      Joseph Thomas
                    </Box>
                    <Typography
                      paragraph
                      sx={classes.partnerTitle}
                      align='center'
                    >
                      <strong>
                        CHARTERED ACCOUNTANT
                      </strong>
                    </Typography>
                  </Box>
                </FrontSide>
                <BackSide
                  style={classes.flippyBody}
                >
                  <Box
                    sx={classes.flippyColor}
                  >
                    <Typography
                      paragraph
                      sx={classes.description}
                    >
                      Commerce Graduate from S.B. College, Changanacherry, Kerala and a Law Graduate from S.D.M. Law College, Mangalore.&nbsp; He is a&nbsp;
                      <strong>
                        Fellow Member of Institute of Chartered Accountants of India
                      </strong>&nbsp;
                      since 1988. He did his articleship with R.G.N. Price &amp; Co, a leading firm of Chartered Accountants and has been in the practice of Chartered Accountancy since 1988.
                    </Typography>
                    <Typography
                      paragraph
                      sx={classes.description}
                    >
                      He has handled various assignments during his professional career including Statutory Audit, Branch Audit of banks, Insurance companies, NBFCs, Internal Audits, Taxation matters, Financial Planning/ Structuring, Company law matters etc. He has successfully undergone the training program for ISA Course, IFRS, and passed the Certificate course on Concurrent Audit of Banks conducted by ICAI. He is the partner in charge at Cochin.
                    </Typography>
                  </Box>
                </BackSide>
              </Flippy>
            </Grid>
          </Grid>
          <Grid
            container
            sx={classes.peopleRow}
          >
            <Grid
              sx={classes.partnerSpacing}
              item
              xs={12}
              md={6}
              lg={4}
            >
              <Flippy
                flipOnHover={false}
                flipOnClick={true}
                flipDirection="horizontal"
                ref={(r) => this.flippy = r}
                style={{ width: '100%', marginBottom: "20px" }}
              >
                <FrontSide
                  style={classes.flippyBody}
                >
                  <Box
                    sx={classes.flippyColor}
                    textAlign='center'
                  >
                    <Box
                      component={"img"}
                      sx={classes.partnerImg}
                      src={img_kuruvilla}
                      alt='Kuruvilla Kuriakose'
                    />
                    <Box
                      component='h3'
                      sx={classes.partnerName}>
                      Kuruvilla Kuriakose
                    </Box>
                    <Typography
                      paragraph
                      sx={classes.partnerTitle}
                      align='center'
                    >
                      <strong>
                        CHARTERED ACCOUNTANT
                      </strong>
                    </Typography>
                  </Box>
                </FrontSide>
                <BackSide
                  style={classes.flippyBody}
                >
                  <Box
                    sx={classes.flippyColor}
                  >
                    <Typography
                      paragraph
                      sx={classes.description}
                    >
                      A Science Graduate and a&nbsp;
                      <strong>
                        Fellow Member of Institute of&nbsp;Chartered Accountants of India
                      </strong>
                      . He did his articleship with Fraser &amp; Ross, a leading firm of Chartered Accountants. He has worked in various capacities in private and public sector. He set up the Accounts department of Kerala State Financial Enterprises and later shifted to Syndicate Bank, Manipal in their accounts department. Later he joined Punjab National Bank in their Loans Department at New Delhi. Subsequently, he joined the private sector and was one of the promoters of Mirza Tanners Ltd, a listed company which is&nbsp; one of India’s largest exporters of leather products. He has had a varied experience in practice and has handled various assignments during his professional career including Statutory Audit, internal Audits, Taxation matters, Financial planning/ Structuring, Company Law matters, business consultancy etc.
                    </Typography>
                  </Box>
                </BackSide>
              </Flippy>
            </Grid>
            <Grid
              sx={classes.partnerSpacing}
              item
              xs={12}
              md={6}
              lg={4}
            >
              <Flippy
                flipOnHover={false}
                flipOnClick={true}
                flipDirection="horizontal"
                ref={(r) => this.flippy = r}
                style={{ width: '100%', marginBottom: "20px" }}
              >
                <FrontSide
                  style={classes.flippyBody}
                >
                  <Box
                    sx={classes.flippyColor}
                    textAlign='center'
                  >
                    <Box
                      component={"img"}
                      sx={classes.partnerImg}
                      src={img_mahesh}
                      alt='Mahesh Jambulingam'
                    />
                    <Box
                      component='h3'
                      sx={classes.partnerName}>
                      Mahesh Jambulingam
                    </Box>
                    <Typography
                      paragraph
                      sx={classes.partnerTitle}
                      align='center'
                    >
                      <strong>
                        CHARTERED ACCOUNTANT
                      </strong>
                    </Typography>
                  </Box>
                </FrontSide>
                <BackSide
                  style={classes.flippyBody}
                >
                  <Box
                    sx={classes.flippyColor}
                  >
                    <Typography
                      paragraph
                      sx={classes.description}
                    >
                      <strong>
                        Associate member of The Institute of Chartered Accountants of India
                      </strong>
                      . He has handled various assignments during his professional career including Statutory Audit, Internal Audits, Taxation matters and Financial planning/ Restructuring. He has served as a senior level executive in the internal audit and Finance departments in TATA Coffee Ltd for nearly fifteen years, where he established the Internal Audit department both for Plantation and Curing Division. Presently he is in practice handling various audit assignments and rendering consultancy services and is the partner in charge of the Bangalore branch.
                    </Typography>
                  </Box>
                </BackSide>
              </Flippy>
            </Grid>
            <Grid
              sx={classes.partnerSpacing}
              item
              xs={12}
              md={6}
              lg={4}
            >
              <Flippy
                flipOnHover={false}
                flipOnClick={true}
                flipDirection="horizontal"
                ref={(r) => this.flippy = r}
                style={{ width: '100%', marginBottom: "20px" }}
              >
                <FrontSide
                  style={classes.flippyBody}
                >
                  <Box
                    sx={classes.flippyColor}
                    textAlign='center'
                  >
                    <Box
                      component={"img"}
                      sx={classes.partnerImg}
                      src={img_ananda}
                      alt='D.S.Ananda'
                    />
                    <Box
                      component='h3'
                      sx={classes.partnerName}>
                      D.S.Ananda
                    </Box>
                    <Typography
                      paragraph
                      sx={classes.partnerTitle}
                      align='center'
                    >
                      <strong>
                        CHARTERED ACCOUNTANT
                      </strong>
                    </Typography>
                  </Box>
                </FrontSide>
                <BackSide
                  style={classes.flippyBody}
                >
                  <Box
                    sx={classes.flippyColor}
                  >
                    <Typography
                      paragraph
                      sx={classes.description}
                    >
                      A Commerce Graduate, who did his articleship under M/s Fraser & Ross and post qualification worked as audit assistant in M/s R.G.N Price and Co. for a period of 2 years. He is also a &nbsp;
                      <strong>
                        Fellow member of Institute of Chartered Accountants of India
                      </strong>
                      . He has served as an executive in a tea plantation, M/s Craigmore Tea Estates, Coonoor for a period of 2 years and later joined Karnataka State Finance Corporation (KSFC), a Government of Karnataka Enterprise and set up the Internal Audit Department in the year 1974 and served in senior positions as executive and retired as Executive Director of KSFC after serving for 30 years. He has vast experience in the field of internal audit, statutory audit, handling taxation matters, Company law matters, financial planning/ Structuring and appraising project finances. He is the partner in charge of the Goa branch.
                    </Typography>
                  </Box>
                </BackSide>
              </Flippy>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
}

export default Team;