const styles = {
    footer: {
        display: "flex",
        height: "100px",
        color: "primary.main",
        alignSelf: "center",
        alignItems: "center",
        fontSize: "13px",
        fontFamily: "Calibri",
    },
    // footbackground:{
    //     background:(theme)=>theme.palette.background.paper
    // }
}

export default styles;