import React, { useState, useEffect } from 'react';
import Logo from "./logo";
import Vision from "./vision";
import About from "./about";
import Notifications from "./notifications";
import Team from "./team";
import Services from "./services";

function HomePage() {
  const [branches, setBranches] = useState({})
  const json = require('./../json/locations.json');
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     branches: {},
  //   }
  // }

  // componentDidMount() {
  //   const json = require('./../json/locations.json');
  //   const branches = JSON.parse(JSON.stringify(json));
  //   this.setState({ branches: branches });
  // }
  useEffect(() => {
    const branches = JSON.parse(JSON.stringify(json));
    setBranches(branches)
  }, [json])

  return (
    <div>
      <Logo branches={branches} />
      <Notifications />
      <Vision />
      <Services />
      <Team />
      <About />
    </div>
  );
}

export default HomePage