const styles = {
    sectionSpacing: (theme) => theme.sectionSpacing,
    peopleRow: {
        justifyContent: "space-evenly",
    },
    description: {
        fontSize: "12px",
        marginTop: "15px",
        marginBottom: "20px",
    },
    flippyBody:{
    //   (theme.palette.type==='light'?{backgroundColor:"red"}:{backgroundColor:"green"})
        // backgroundColor:"red",
        padding:"0px"
    }
    ,
    partnerImg: {
        marginTop: "10px",
        borderRadius: "50% !important",
        paddingTop: "10px",
        '&:hover': (theme) => theme.actions.scale,
        height:"230px",

    },
    partnerName: {
        fontWeight: "bold",
        marginTop: "28px",
        marginBottom: "8px",
        color: "inherit"
    },
    partnerTitle: {
        textTransform: "uppercase",
        fontWeight: "bold",
        color: "#d0d0d0",
        letterSpacing: "2px",
        fontSize: "13px",
        paddingBottom: "10px",
    },
    partnerSpacing:{
        paddingLeft:"15px",
        paddingRight: "15px" 
    },
    flippyColor:{
        backgroundColor:"background.paper",
        height:'100%',
        padding:"15px"
    }

}

export default styles;